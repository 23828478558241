import React /*, { useReducer }*/ from 'react'
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core'
import useIntl from '../hooks/useIntl'
import { Button } from '@material-ui/core'

const PcsActionDialog = ({ show, pcsOpType='transferToPcs', onPcsAction, onCancel, selectedReleaseAddresses, containersForPcs }) => {

	const { translate } = useIntl()

	const title = translate(`pcs.${pcsOpType}.dialog.title`);

	const noPcsAvailable = containersForPcs.filter(c => !c.ediPcs);
	const alreadySentToPcs = pcsOpType === 'transferToPcs' ? containersForPcs.filter(c => c.sentToPcs === true) : [];
	const alreadyTransferred = pcsOpType === 'transferToPcs' ? containersForPcs.filter(c => c.transferStatus === 'transferred') : [];
	const eligibleContainers = pcsOpType === 'transferToPcs' ? containersForPcs.filter(c => c.transferStatus !== 'transferred' && !!c.ediPcs && c.sentToPcs !== true) : containersForPcs;

	const actions = (
		<div style={controlsStyle}>
			<Button variant="contained" color="secondary" onClick={() => onCancel()}>{translate('general.cancel')}</Button>
			<Button variant="contained" disabled={eligibleContainers.length === 0} color="primary" onClick={() => onPcsAction(pcsOpType, eligibleContainers)}>
				{translate(`blActions.${pcsOpType}`)}
			</Button>
		</div>
	)

	return (
		<Dialog open={show} onClose={onCancel}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				{ 
					eligibleContainers.length > 0 &&
					<div>
						{ translate(`pcs.${pcsOpType}.dialog.confirm`, { numberOfReleases: eligibleContainers.length || 0 }) }
						<ul>
						{
							eligibleContainers.map((c, idx) => <li key={idx}>{c.containerNumber} {pcsOpType === 'transferToPcs' ? 'to' : 'from'} {c.ediPcs}</li>)
						}
						</ul>
					</div>
				}
				{
					noPcsAvailable.length > 0 &&
					<div>
						{ translate(`pcs.${pcsOpType}.dialog.noPcsAvailable`) }
						<ul>
						{
							noPcsAvailable.map((c, idx) => <li key={idx}>{c.containerNumber}</li>)
						}
						</ul>
					</div>
				}
				{
					alreadySentToPcs.length > 0 &&
					<div>
						{ translate(`pcs.${pcsOpType}.dialog.alreadySentToPcs`) }
						<ul>
						{
							alreadySentToPcs.map((c, idx) => <li key={idx}>{c.containerNumber}</li>)
						}
						</ul>
					</div>
				}
				{
					alreadyTransferred.length > 0 &&
					<div>
						{ translate(`pcs.${pcsOpType}.dialog.alreadyTransferred`) }
						<ul>
						{
							alreadyTransferred.map((c, idx) => <li key={idx}>{c.containerNumber}</li>)
						}
						</ul>
					</div>
				}
			</DialogContent>
			<DialogActions>{actions}</DialogActions>
		</Dialog>
	)
}

export default PcsActionDialog

const controlsStyle = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
	padding: 10,
}
