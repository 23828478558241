import React, { useReducer, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import client from '../../feathers';
import TMDialog from '../../lib/Dialog/TMDialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useIntl from '../../hooks/useIntl';
import { assignReleases, unAssignReleases, checkIsReleaseAssigned } from '../../services/releases';
import AssignmentReport from '../../components/AssignmentReport';
import CustomTabs from '../CustomTabs';
import ReleasesDriver from './drivers/drivers';
import ReleasesBarges from './barges/barges';
import ReleasesTrains from './trains/trains';
import { reducer, INITIAL_STATE } from './transporters.reducer';
import { useAlertDialog } from '../../context/AlertDialogProvider';
import useSentry from '../../hooks/useSentry';
import { useProgressDialog } from '../../context/ProgressDialogProvider';
import DriverIdentificationMissingError from '../../util/DriverIdentificationMissingError';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import useAuth from '../../hooks/useAuth';
import { isTokenValid } from '../../util/token';
import useWalletError from '../../hooks/useWalletError';
import '../style.css';

const ReleasesTransporters = ({
  selectedReleaseAddresses,
  onCancel,
  transporters: { dialog: show, type },
  refreshBills,
  anonAddresses,
  containersForAssign
}) => {
  const onWalletError = useWalletError();
  const { translate } = useIntl();
  const { user } = useAuth();
  const { showAlert } = useAlertDialog();
  const logSentry = useSentry();
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [tabs, setTabs] = useState([])
  const history = useHistory();

  useEffect(() => { 
    show && handleTransporters()
  }, [show]);

  /* Not every org has all 3 subscriptions, so in order to have a correct mapping between label and index for the Tabs
     we need this mapping */
  useEffect(() => { 
    let userTabs = [];
    if (user.features.canAssignDriver) userTabs.push('driver');
    if (user.features.canAssignBarge) userTabs.push('barge');
    if (user.features.canAssignTrain) userTabs.push('train');
    setTabs(userTabs);
  }, [user]);

  /* 
    * Perform assign operation 
    * Declared early, so confirmDialog can use it
  */
  const doAssign = async () => {
    console.log("state.identification", state.identification)
    let walletResult = [];

    // Backend first, will return successfully processed releasAddresses
    const dbResult = await assignReleases({
      identification: state.identification,
      identificationType: state.identificationType,
      type: state.activeTab,
      releaseAddresses: selectedReleaseAddresses,
      visitNumber: state.visitNumber,
      anonAddresses
    });
    console.debug("assign result", dbResult.data)

    if (dbResult.data.eligible_releases.length) {
      let assignData = { identification: ""+state.identification, type: state.identificationType === 'itsmeCode' ? 'itsmeID' : state.activeTab }
      if (['barge', 'train'].includes(state.activeTab)) { 
        assignData.visitNumber = state.visitNumber;
      }

      // use successfully processed releaseAddresses for chain operation
      try {
        walletResult = await window.walletApi.assignReleases(
          dbResult.data.eligible_releases.map(({address,version}) => ({address,version})), 
          assignData,
          { from: user.organization }
        );
        
        // Format successful results: { address, result: { newOwner } }
        // Format error results: { address, error }
        const failedAssigns = walletResult.filter(r => r.error);
        if (failedAssigns.length > 0) {
          // no need to catch the result, the right error message is already in the walletResult
          await doUnassign(failedAssigns.map(r => r.address));
          // only need to add the containerNumber
          walletResult.forEach(r => {
            if (r.error) {
              const releaseData = dbResult.data.eligible_releases.find(er => er.address === r.address)
              if (releaseData) {
                // mimic the object structure for the AssignReport
                r.container = { containerNumber: releaseData.container.containerNumber }
              }
            }
          })
        }

      } catch (error) {
        logSentry(error);
        // Assign was successful on backend, but not on chain.
        // So undo the assign alltogether and report the error
        const unassignResult = await doUnassign();
        walletResult = unassignResult.dbResult.data.eligible_releases.map(r => ({ address: r.address, error: error }))
      }
    }

    return { dbResult, walletResult };
  }

  const doUnassign = async (releaseAddresses=selectedReleaseAddresses) => {
    let walletResult = [];
    // Backend first, will return successfully processed releasAddresses
    const dbResult = await unAssignReleases({ releaseAddresses, anonAddresses });

    if (dbResult.data.eligible_releases.length) {
      try {
        walletResult = await window.walletApi.unassignReleases(
          dbResult.data.eligible_releases.map(({address,version}) => ({address,version})), 
          { from: user.organization }
        );
      } catch (error) {
        logSentry(error);
        walletResult = dbResult.data.eligible_releases.map(r => ({ address: r.address, error: error }))
      }
    }
    return { dbResult, walletResult };
  }

  const handleTransporters = async () => {
    dispatch({ type: 'RESET' });

    const { data: { assignedDrivers, assignedBarges, assignedTrains } } = await checkIsReleaseAssigned({ releaseAddresses: selectedReleaseAddresses, anonAddresses });
    const uniqueDrivers = [...new Set(assignedDrivers.map(r => r.driverId))];
    const uniqueBarges = [...new Set(assignedBarges.map(r => r.bargeId))];
    const uniqueTrains = [...new Set(assignedTrains.map(r => r.trainId))];
    const uniqueVisits = [...new Set(assignedBarges.concat(assignedTrains).map(r => r.visitNumber))];
    
    const sortedDrivers = await handleSortedTransports('drivers', uniqueDrivers);
    const sortedBarges = await handleSortedTransports('barges', uniqueBarges);
    const sortedTrains = await handleSortedTransports('trains', uniqueTrains);

    // Handle preselecting driver of barge
    let isPreselected = handlePreselect('drivers', assignedDrivers, uniqueDrivers, sortedDrivers);

    if (!isPreselected) {
      // Only handle preselecting a barge when there was no driver preselected
      isPreselected = handlePreselect('barges', assignedBarges, uniqueBarges, sortedBarges);
      if (!isPreselected) {
        // Only handle preselecting a train when there was no driver or barge preselected
        handlePreselect('trains', assignedTrains, uniqueTrains, sortedTrains);
      }
      
      // Handle unique visitnumber
      if (uniqueVisits.length === 1 && 
          (assignedBarges.length === selectedReleaseAddresses.length || assignedTrains.length === selectedReleaseAddresses.length)
      ) {
        // if ALL the selectedReleaseAddresses are assigned to a single visitNumber, pre-fill the visit number
        handleVisitNumber(uniqueVisits[0]);
      }
    }

    // handle warnings
    if (uniqueDrivers.length > 1 || 
        uniqueBarges.length > 1 || 
        uniqueTrains.length > 1 || 
        uniqueDrivers.length + uniqueBarges.length + uniqueTrains.length > 1 || 
        uniqueVisits.length > 1) {
      dispatch({ type: 'SET_WARNING_TITLE', payload: translate('assign.warning.title') });
      dispatch({ type: 'SET_WARNING_TEXT', payload: translate('assign.warning.text') });
    }
    
  }

  const handleSortedTransports = async (type, uniqueTransports) => {
    const transports = await client.service(type).find();

    let sortedTransports = []; 

    // Sometimes the release will be flagged as assigned, but after it was transferred. So the trnasporter won't be found in our list
    // This will throw an exception, so check that first
    if (uniqueTransports && uniqueTransports.length > 0 && transports.data.find(t => t.id === uniqueTransports[0])) {
      // if ALL the selectedReleaseAddresses are assigned to a single transport type (barge/driver),
      // put the unique transport type in front of the others in order to select and show it in the table.
      // otherwise, a selected transport type may be on page 2 of the table and go unnoticed.
      sortedTransports = transports.data.filter(t => t.id !== uniqueTransports[0])
      sortedTransports.unshift(transports.data.find(t => t.id === uniqueTransports[0]))
    } else {
      sortedTransports = transports.data
    }
    
    // console.log("sortedTransports", sortedTransports)
    // console.log("uniqueTransports", uniqueTransports)

    dispatch({ type: `SET_${type.toUpperCase()}`, payload: sortedTransports })
    return sortedTransports;
  }

  const handlePreselect = (type, assignedTransports, uniqueTransports, sortedTransports) => {
    if (assignedTransports.length === selectedReleaseAddresses.length && uniqueTransports.length === 1) {
      // pre-select the first row
      dispatch({ type: `SET_SELECT_FIRST_${type.toUpperCase()}`, payload: true });
      
      // switch to the right tab (this will not work if you have only 1 'canAssign' subscription)
      if (tabs.length > 1) {
        // Note:  a clumsy workaround was needed here in order to show the preselected row if necessary:
        //        The tabs are first rendered with selectFirstRow=false. the table is NOT rerendered when this value changes to true.
        //        The only way (for now) to rerender the table, was to switch the tabs. 
        //        So switching to another tab here first, and then switching back to the required one...
        const targetTab = tabs.indexOf(type.slice(0, -1));
        handleOnTabChange(targetTab > 0 ? 0 : 1);
        handleOnTabChange(targetTab)
      }

      // enable the submit button
      handleIdentification(sortedTransports[0]['identification'])
      return true;
    } else {
      return false;
    }
  }

  const handleIdentification = (identification) => {
    dispatch({
      type: 'SET_IDENTIFICATION',
      payload: identification,
    });
  }

  // const goToDriversScreen = () => {
  //   history.push('/drivers');
  // }

  // const { showConfirmDialog } = useConfirmDialog(
  //   'identify.driver.confirm.title', 'identity.driver.confirm.message.itsme', 
  //   handleIdentification, goToDriversScreen,
  //   'identity.driver.confirm.understood', 'identity.driver.confirm.gotoDriversScreen'
  // );

  // used for drivers
  // identifcation = [`${firstName} ${lastName}`, phone, email, alfaPass, itsmeCode, id]
  const checkIdentification = (identification) => {  

    // if identification is undefined, it's a de-select
    if (!identification) {
      handleIdentification(identification);
    } else {
      // console.log("containersForAssign", containersForAssign)
      try {
        if (containersForAssign.some(c => c.terminalIdentificationMethod?.toLowerCase() === "alfapass")) { 
          if(!identification[3]) {
            handleIdentification(undefined); // clear identification, so the assign button gets disabled
            throw new DriverIdentificationMissingError(translate("identity.driver.confirm.message.alfapass"));
          } else {
            // set identification to alfapass
            handleIdentification(identification[3]);
            dispatch({ type: 'SET_IDENTIFICATION_TYPE', payload: 'alfaPass' });
          }
        }

        if (containersForAssign.some(c => c.terminalIdentificationMethod?.toLowerCase() === "itsme")) { 
          if(!identification[4]) {
            // PHASE 1: WARN
            // showConfirmDialog(identification[5]); // pass the driverID instead of the itsmeCode
            // dispatch({ type: 'SET_IDENTIFICATION_TYPE', payload: 'id' });
            
            // PHASE 2: BLOCK
            handleIdentification(undefined); // clear identification, so the assign button gets disabled
            throw new DriverIdentificationMissingError(
              "Some containers you selected require the driver to identify with Itsme. Your selected driver has not yet been identified with Itsme."
            );
          } else {
            // set identification to itsmeCode
            handleIdentification(identification[4]);
            dispatch({ type: 'SET_IDENTIFICATION_TYPE', payload: 'itsmeCode' });
          }
        }

        // No alfapass or no itsme required? Use the driverID
        if (!containersForAssign.some(c => c.terminalIdentificationMethod?.toLowerCase() === "alfapass") && 
            !containersForAssign.some(c => c.terminalIdentificationMethod?.toLowerCase() === "itsme")) 
        {
          handleIdentification(identification[5]);
          dispatch({ type: 'SET_IDENTIFICATION_TYPE', payload: 'id' });
        }
      } catch (error) {
        handleIdentification(undefined); // clear identification, so the assign button gets disabledMIX
        showAlert("Identification missing", error.message, "sm");
      }
    }
  }
  
  const handleVisitNumber = (visitNumber) => {
    dispatch({
      type: 'SET_VISITNUMBER',
      payload: visitNumber,
    });
  }

  const handleOnTabChange = (result) => {
    dispatch({
      type: 'SET_ACTIVE_TAB',
      payload: tabs[result]
    });
  };

  const handleClick = async (operation) => {
    let operationResults;

    if (!isTokenValid()) {
      onWalletError(new Error(translate('session.expired')));
      return;
    }

    try {
      showProgressDialog();
      if (operation === 'assign') {
        operationResults = await doAssign();
      } else {
        operationResults = await doUnassign();
      }

      if (operationResults.walletResult.length > 0) {
        operationResults.dbResult.data.walletErrors = operationResults.walletResult.filter(r => r.error);
        const walletSuccesses = operationResults.walletResult.filter(r => !r.error).map(r => r.address);
        operationResults.dbResult.data.eligible_releases = operationResults.dbResult.data.eligible_releases.filter(r => walletSuccesses.includes(r.address))
      }

      // close dialog
      onCancel();
      // show result
      showAlert(
        translate(`assignmentReport.${operation}.results`),
        <AssignmentReport
          data={operationResults.dbResult.data} 
          operation={operation} 
        />
      );
    } catch (error) {
      logSentry(error);
      showAlert(translate('general.error'), error.message, "sm");
    } finally {
      await refreshBills(true, false);
      hideProgressDialog();
    }
  }

  return (
    <TMDialog
      key={type}
      title={translate(`blActions.${type}`)}
      dialogOpen={show}
      handleDialogClose={onCancel}
      maxWidth="md"
      showBottomClose={false}
    >
      <React.Fragment>
        <Typography variant="body2">
          {translate(`transporters.${type}.dialog.line_1`, {
            numberOfReleases: selectedReleaseAddresses.length || 0,
            tab: state.activeTab
          })}<br/>
          { type === 'assign' && 
            translate(`transporters.${type}.dialog.line_2`, { 
              tab: state.activeTab
            })}
        </Typography>
        { !!state.warningTitle && 
          <p>
            <b>Warning: { state.warningTitle }</b><br/>
            { state.warningText }
          </p>
        }
        {type === 'assign' && (
          <CustomTabs
            selectedTab={tabs.indexOf(state.activeTab)}
            onTabChange={handleOnTabChange}
            content={[
              user.features.canAssignDriver 
              ? {
                  label: 'Drivers',
                  component: (
                    <ReleasesDriver
                      drivers={state.drivers}
                      onSelect={checkIdentification}
                      selectFirstRow={state.selectFirstDriver}
                      refreshData={handleSortedTransports}
                    />
                  )
                }
              : {}
              ,
              user.features.canAssignBarge 
              ? {
                  label: 'Barges',
                  component: (
                    <ReleasesBarges
                      barges={state.barges}
                      onSelect={handleIdentification}
                      handleVisitNumber={handleVisitNumber}
                      selectFirstRow={state.selectFirstBarge}
                      prefilledVisitNumber={state.visitNumber} 
                      refreshData={handleSortedTransports}
                    />
                  )
                }
              : {}
              ,
              user.features.canAssignTrain 
              ? {
                  label: 'Trains',
                  component: (
                    <ReleasesTrains
                      trains={state.trains}
                      onSelect={handleIdentification}
                      handleVisitNumber={handleVisitNumber}
                      selectFirstRow={state.selectFirstTrain}
                      prefilledVisitNumber={state.visitNumber} 
                      refreshData={handleSortedTransports}
                    />
                  )
                }
              : {}
            ].filter(t => t.label)}
          />
        )}
        <div style={{padding: '25px 10px 0px 10px'}}>
          <Button variant="contained" color="primary" disabled={type === 'assign' && state.identification === undefined} onClick={() => handleClick(type)}>
            {translate(`blActions.${type}`)}
          </Button>
          &nbsp;
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {translate('general.cancel')}
          </Button>
        </div>
      </React.Fragment>


    </TMDialog>
  );
};

export default ReleasesTransporters;
