import React, { useState, useReducer, useEffect } from 'react'
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { pickBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import TMForm from '../lib/Form/TMForm';
import client from '../feathers';
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/AddBox';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EmailIcon from '@material-ui/icons/Email';
import Switch from '@material-ui/core/Switch';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import BarChartIcon from '@material-ui/icons/BarChart';
import useIntl from '../hooks/useIntl';
import Typography from '@material-ui/core/Typography';
import TMDataTable from '../lib/Table/TMDataTable';
import TMDialog from '../lib/Dialog/TMDialog';
import { useProgressDialog } from '../context/ProgressDialogProvider';
import { useAlertDialog } from '../context/AlertDialogProvider';
import useConfirmDialog from '../hooks/useConfirmDialog';
import useSentry from '../hooks/useSentry';
import useAuth from '../hooks/useAuth';
import AddTransporterDialog from '../components/releases-transporters/AddTransporterDialog.jsx';
import { useSuccessRateOfSecurePickup } from '../hooks/useSuccessRateOfSecurePickup';
import { addtoUserAuditTrail } from '../util/userAuditTrail';
import { Http } from '../axios';

const useStyles = makeStyles((theme) => ({
  hoverButton: {
    color: '#757575',
    '&:hover': {
    	color: theme.palette.primary.main
    }
  },
  light: {
  	color: theme.palette.secondary.main
  },
  inactive: {
    '& td': {
      color: theme.palette.secondary.main,
    }
  },
  identificationRequired: {
    backgroundColor: theme.palette.primary.light
  },
  itsmeLinkDiv: {
  	margin: '20px',
  	padding: '20px',
  	border: `solid 1px ${theme.palette.primary.main}`,
  	backgroundColor: theme.palette.primary.light,
  	wordWrap: 'anywhere'
  }
}));

export default function DriversOverview() {
	
	const [openAdd, setOpenAdd] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [openItsmeLink, setOpenItsmeLink] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { showAlert } = useAlertDialog();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { translate } = useIntl();
	const classes = useStyles();
	const logSentry = useSentry();
  const { user } = useAuth();
  const  { successRateOfSecurePickupJsx, openSrsPickupDialog }  = useSuccessRateOfSecurePickup('drivers');
	const history = useHistory();

	const fetchDrivers = async () => {
    const result = await client.service('drivers').find();
    dispatch({ payload: { drivers: result.data } })
  };

  useEffect(() => {
    fetchDrivers();
  }, []);

  const columns = [
		{
    	name: translate('driver.lastName'),
    	options: { filter: false }
    },
		{
    	name: translate('driver.firstName'),
    	options: { filter: false }
    },
		{
    	name: translate('driver.email'),
    	options: { filter: false }
    },
		{
    	name: translate('driver.phone'),
    	options: { filter: false }
    },
		{
    	name: translate('driver.alfapass'),
    	options: { filter: false }
    },
		{
      name: 'itsme',
      options: { filter: false, download: false }
    },
    {
    	name: 'Identification required',
    	options: { display: false, filter: true, download: false, viewColumns: false }
    },
		{ 
			name: 'Action',
			options: { filter: false, download: false, viewColumns: false },
		},
		{
      name: "isActive",
      options: { display: false, filter: false, download: false, viewColumns: false }
    }, 
	];

	const getData = () => {
		return state.drivers.map(d => [
			d.lastName, 
			d.firstName, 
			d.email, 
			d.phone, 
			d.alfaPass 
      	? <CheckIcon color="primary"/>
      	: <React.Fragment/>,
      d.itsmeCode
      	? <CheckIcon color="primary"/>
      	: <React.Fragment/>,
      (!d.alfaPass && !d.itsmeCode ? "Required" : "Done"), // Identification required
			<IconButton className={classes.hoverButton} onClick={() => openEditDriver(d)}><EditIcon/></IconButton>,
			d.isActive
		])
	}

	const openEditDriver = async (driver) => {
    dispatch({ payload: {
    	firstName: driver.firstName,
			lastName: driver.lastName,
			email: driver.email,
			phone: driver.phone,
			alfaPass: driver.alfaPass,
			isActive: driver.isActive,
			organizationAddress: driver.organization_address,
			id: driver.id,
			itsmeData: driver.itsmeData ? `${driver.itsmeData.given_name} ${driver.itsmeData.family_name}` : '(not registered)'
    }});
    setOpenEdit(true);
  };

  const handleDialogClose = async () => {
    await fetchDrivers();
    dispatch({ type: 'RESET' });
  	openEdit && setOpenEdit(false);
  	openAdd && setOpenAdd(false);
  	openItsmeLink && setOpenItsmeLink(false);
  }

	const handleChange = (e) => {
    dispatch({ payload: { 
    	[e.target.name]: (e.target.hasOwnProperty('checked') ? e.target.checked : e.target.value)
    }})
  };

  const checkEdit = async () => {
  	const currentDriver = state.drivers.find(d => d.id === state.id);
  	// check if driver was inactivated 
  	if (!state.isActive && currentDriver.isActive) {
  		showConfirmEditDialog('');
  	} else {
  		handleEditDriver();
  	}
  }

	const handleEditDriver = async () => {
    let didNotError = true;
    try {
    	showProgressDialog();
			const data = {
				firstName: state.firstName, 
				lastName: state.lastName, 
				email: state.email, 
				phone: state.phone,
				alfaPass: state.alfaPass, 
				isActive: state.isActive
			};
		  const result = await client.service('drivers').patch(state.id, data);

    	const currentDriver = state.drivers.find(d => d.id === state.id);
    	addtoUserAuditTrail(
				'edit driver', 
				{ 
					id: state.id, 
					old: pickBy(currentDriver, (v,k) => (data.hasOwnProperty(k) && data[k] !== v)), // only add the changed values 
					new: pickBy(data, (v,k) => currentDriver[k] !== v) // only add the changed values
				}
			);

    	// check if driver was inactivated or alfapass number changed
    	if ((!state.isActive && currentDriver.isActive) || (state.alfaPass !== currentDriver.alfaPass)) {
    		enqueueSnackbar(translate('driver.edit.warning.unassigning'), { persist: true });
    		await window.walletApi.unassignReleases(
          result.removedReleases.map(({release}) => ({ address: release.address, version: release.version })),
          { from: user.organization }
        );
      	closeSnackbar();
    	}
  		enqueueSnackbar(translate('driver.patched'), { autoHideDuration: 1500 });
  		
    } catch (error) {
      logSentry(error);
    	showAlert(translate('general.error'), error.message, "sm");
    	didNotError = false;
    } finally {
      didNotError && await fetchDrivers();
      handleDialogClose();
      hideProgressDialog();
    }
	}

	const { showConfirmDialog: showConfirmEditDialog } = useConfirmDialog(
		'driver.edit', 'driver.edit.inactive.warning', 
		handleEditDriver, handleDialogClose,
		'general.yes', 'general.no'
	)

	const setRowProps = (row, dataIndex) => {
    // last field = isActive
    if(row[row.length-1] === false) return { className: classes.inactive }
		// row[6] = identification required
    if(row[6] === "Required") return { className: classes.identificationRequired }
  };

	const showLink = async () => {
		try {
			const result = await client.service('drivers').patch(state.id, { stateCode: state.stateCode, copyItsmeLink: true });		
			dispatch({ payload: { itsmeLink: result }});
			setOpenItsmeLink(true);
		} catch (err) {
			showAlert(translate("general.error"), translate("general.error.detail", { error: err.message }), 'sm');
		}
	}

	const copyLink = () => {
		let textToCopy = document.querySelector("#itsmelink").innerHTML.replaceAll('&amp;', '&');
		navigator.clipboard.writeText(textToCopy);
    enqueueSnackbar('Link copied successfully', { autoHideDuration: 2000 })
	}

	const resendLink = async () => {
		if (state.email && (/^([\w.&%+-]+)@([\w-]+\.)+([\w]{2,})$/).test(state.email)) {
			try {
				const result = await client.service('drivers').patch(state.id, { email: state.email, sendItsmeLink: true });
				enqueueSnackbar(`Email with link to register to itsme sent to driver ${state.firstName} ${state.lastName} successfully.`, { autoHideDuration: 2000 })
				addtoUserAuditTrail('resendLink to driver', { id: state.id, email: state.email }
			);
			} catch (err) {
				showAlert(
					"Error sending email", 
					`There was an error sending the link to register with itsme to your driver: ${err.message}.`,
					'sm'
				);
			}
		} else {
			showAlert(
				"Missing email address", 
				`Please provide a valid email address for your driver in order to send the link to register with itsme.`,
				'sm'
			);
		}
	} 

	const removeItsmeCode = async () => {
		try {
			await client.service('drivers').patch(state.id, { itsmeCode: null, itsmeData: null });
			handleDialogClose();		
	    enqueueSnackbar('Itsme integration removed successfully', { autoHideDuration: 2000 })
	    addtoUserAuditTrail('removeItsmeCode from driver', { id: state.id });
		} catch (err) {
			showAlert(translate("general.error"), translate("general.error.detail", { error: err.message }), 'sm');
		}
	}

	const { showConfirmDialog: showConfirmDeleteItsmeCodeDialog } = useConfirmDialog(
		'driver.edit', 'driver.delete.itsmeCode', 
		removeItsmeCode, handleDialogClose,
		'general.yes', 'general.no'
	)

	return (
		<React.Fragment>
			<div className="drivers-table">
				<TMDataTable
		      title={'Drivers'}
		      columns={columns}
		      data={getData()}
		      setRowProps={setRowProps}
		      options={{
			      print: false,
			      filterType: 'dropdown',
			      viewColumns: false,
			      customToolbar: () => (
              <React.Fragment>
                <IconButton
                  className={classes.hoverButton}
                  onClick={() => openSrsPickupDialog()}
                  >
                  <BarChartIcon />
                </IconButton>
                <IconButton
	                className={classes.hoverButton}
	                onClick={() => setOpenAdd(true)}
	                >
	                <CreateIcon />
                </IconButton>
              </React.Fragment>
				    ),
		      }}
		    />
	    </div>

	    <AddTransporterDialog
				type={'driver'} 
				openAdd={openAdd} 
				handleDialogClose={handleDialogClose}
			/>

			<TMDialog
	      key="edit-driver"
	      title={"Edit driver"}
	      dialogOpen={openEdit}
	      handleDialogClose={handleDialogClose}
	      maxWidth="md"
	      showBottomClose={false}
	    >
	      <TMForm
	        object={state}
	        handleChange={handleChange}
	        handleSubmit={checkEdit}
	        submitLabel={translate("driver.edit")}
	        handleCancel={handleDialogClose}
	        cancelLabel={translate("general.cancel")}
	        fields={[
	    			{ field: 'firstName', label: 'First name', required: true }, 
	    			{ field: 'lastName', label: 'Last name', required: true }, 
	    			{ field: 'email', label: 'Email', isEmail: true },
	    			{ field: 'phone', label: 'Phone', type: 'tel' },
	    			{ field: 'isActive', label: 'Active ?', componentType: Switch, fullWidth: true },
	    			{ field: 'alfaPass', label: 'Alfapass', helperText: translate('driver.alfapass.helpertext') },
	    			{ field: 'itsmeData', label: 'Itsme linked to', disabled: true, helperText: translate('driver.itsme.helpertext'),
							suffix: (
								state.itsmeData === '(not registered)' 
									? <React.Fragment>
											<Tooltip title={"E-mail link to register with itsme"}><IconButton aria-label="E-mail link" onClick={resendLink} size="small"><EmailIcon /></IconButton></Tooltip>
											<Tooltip title={"Show link to register with itsme"}><IconButton aria-label="Show link" onClick={showLink} size="small"><VisibilityIcon /></IconButton></Tooltip>
										</React.Fragment>
									: <Tooltip title={"Remove itsme integration"}><IconButton aria-label="Remove my itsme integration" onClick={()=>showConfirmDeleteItsmeCodeDialog('')} size="small"><DeleteIcon /></IconButton></Tooltip>
							)
            } 
	        ]}
	      />
	    </TMDialog>

	    <TMDialog
	      key="copy-itsmelink"
	      title={"Use this link to email your driver personally"}
	      dialogOpen={openItsmeLink}
	      handleDialogClose={handleDialogClose}
	      maxWidth="sm"
	      showTopClose={false}
	      handleSubmit={copyLink}
	      btnLabel={"Copy link to clipboard"}
	    >
	    	<div className={classes.itsmeLinkDiv} id="itsmelink">{state.itsmeLink}</div>
	    </TMDialog>

			{/* Start - Success Rate of Secure Pickup Dialog */}
	   	{ successRateOfSecurePickupJsx }
	  	{/* End - Success Rate of Secure Pickup Dialog */}
	
		</React.Fragment>
	);
}

const initialState = {
  drivers: [],
  // driverToIdentify: {},
  id: undefined,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  alfaPass: '',
  isActive: false,
  organizationAddress: '',
  stateCode: '',
  itsmeData: '',
  itsmeLink: '',
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'RESET':
      return { ...initialState, drivers: state.drivers }
    default:
      // = SET
      return { ...state, ...payload }
  }
}
