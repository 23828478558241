import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import Grid from '@material-ui/core/Grid';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { Http } from '../axios';
import TMPaper from '../lib/Paper/TMPaper';
import TMAppBar from '../lib/AppBar/TMAppBar';
import CookieBanner from '../components/CookieBanner';
import { addtoUserAuditTrail } from '../util/userAuditTrail';

export default function ItsmeConfirmPage() {

	const [status, setStatus] = useState();
	const [responseData, setResponseData] = useState({});

	const [cookies] = useCookies(['cookieAccepted']);
	const showCookieBanner = cookies.cookieAccepted === undefined;

	const postItsmeCode = async() => {
    const itsmeCode = new URLSearchParams(window.location.search).get('code');
    const accessToken = new URLSearchParams(window.location.search).get('state');

    if (accessToken) {
    	if (itsmeCode) {
    		try {
		      const response = await Http.post('/driver/itsme', { itsmeCode }, { jwt: accessToken });
		      setResponseData(response.data);
		      addtoUserAuditTrail('Driver linked with itsme', response.data);
		      setStatus(true);
		    } catch (error) {
	      	setStatus(false);
	      	setResponseData(error.message);
		    }
	    } else {
	      setStatus(false);
	    }
		} else {
      setStatus(false);
		}
  }

  useEffect(() => {
    postItsmeCode();
  }, []);

	return (
		<React.Fragment>
			<TMAppBar
        user={{}}
        isAuthenticated={false}
        logoPath="/tmining-logo-mix.png"
      />
			<Grid container style={{width: '75%', margin: '20px auto' }}>
	      <Grid item xs={12} lg={8} style={{ margin: 'auto' }}>
	      	<TMPaper title="Itsme registration result">
	        	{
	        		status === undefined 
	        		? 'Loading...'
	        		: (	status 
	        				? <span><DoneIcon style={{color:"#4CBB17"}}/>Thank you, your itsme registration has completed successfully. You may close this window.</span>
	        				: <span><ClearIcon style={{color:"red"}}/>Error during itsme registration. Please restart your itsme verification.</span>
	        			) 
	        	}
	        	{ 
	        		(status !== undefined) &&
	        		<div style={{display: 'none'}}>
	        			<p><b>Received:</b></p>
		        		<pre>{JSON.stringify(responseData)}</pre>
		        	</div>
	        	}
    			</TMPaper>
	      </Grid>
	      { showCookieBanner && <CookieBanner position='left' /> }
	    </Grid>
		</React.Fragment>
	);
}